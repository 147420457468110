var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-5 w-100 my-5"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"bg-white rounded-4 border mx-2 widget-list w-25"},[_c('h1',{staticClass:"px-3 py-3 page-heading",staticStyle:{"background":"#f1f7ff","font-weight":"bold","border-radius":"14px 14px 0 0","margin-bottom":"0"}},[_vm._v(" Selected Modules widgets ")]),_c('draggable',{staticClass:"draggable-list d-grid widgets-list w-90 p-4",staticStyle:{"grid-template-columns":"repeat(2, 1fr)","gap":"3%","overflow":"auto","height":"550px"},attrs:{"list":_vm.widgetsList,"group":{ name: 'myGroup', pull: true, put: false }},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd}},_vm._l((_vm.widgetsList),function(element,index){return _c('div',{key:element.id,staticStyle:{"margin-left":"auto","margin-right":"auto"},attrs:{"id":index}},[(element.function_type === 'generic')?_c('div',{staticClass:"generic-widget"},[_c('img',{staticStyle:{"margin":"auto"},attrs:{"src":element.image,"alt":""}})]):(
                            element.function_type === 'core' && element.height === 'c' && element.width === 'c'
                        )?_c('div',{staticClass:"core-widget",style:({ background: element.function_name == 'Games2' ? '#4CBC9A' : 'white' })},[_c('img',{attrs:{"src":element.image,"alt":""}})]):(element.function_name == 'Web Links2')?_c('div',{staticClass:"core-class",staticStyle:{"background-color":"rgb(81, 85, 195)"}},[_c('img',{attrs:{"src":element.image,"alt":""}})]):_c('div',{staticClass:"core-class"},[_c('img',{attrs:{"src":element.image,"alt":""}})])])}),0)],1),_c('div',{staticClass:"bg-white rounded-4 border mx-2 dash-builder w-75"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center",staticStyle:{"background":"#f1f7ff","border-radius":"14px 14px 0 0"}},[_c('h1',{staticClass:"px-5 py-3 page-heading",staticStyle:{"font-weight":"bold"}},[_vm._v(" Dashboard Builder "+_vm._s(_vm.selectedOption)+" ")])]),(_vm.templateStyles)?_c('div',{staticClass:"droppable-template"},[_c('div',{staticClass:"w-100",style:(_vm.templateStyles.primeContainer)},[_c('div',{style:(_vm.templateStyles.bgImageContainer)},[_c('img',{style:(_vm.templateStyles.bgImage),attrs:{"src":require("@/assets/px/drag-and-drop/MHH-FC-Background.png"),"alt":"","width":"100","height":"100"}})]),_c('div',{staticClass:"w-100",style:(_vm.templateStyles.primeContainer)},[_vm._m(0),_c('div',{staticClass:"t-m-c w-100",style:(_vm.templateStyles.templateMainContainer)},[_c('div',{staticClass:"u-l-c w-100 d-flex h-100",staticStyle:{"gap":"10px","margin-bottom":"50px"}},[_c('div',{staticClass:"w-50 px-2",staticStyle:{"align-items":"center","place-items":"center","display":"grid","grid-template-columns":"repeat(2, 1fr)","grid-template-rows":"repeat(2, 1fr)"}},_vm._l((_vm.moduleItems),function(item,index){return _c('div',{key:item[1].name + '',class:_vm.width && _vm.height && !item[1].elements.length
                                                ? ' highlighted-section ' + item[1].class
                                                : '' + item[1].class,style:(_vm.moduleItemsStyle(item))},[_c('draggable',{staticClass:"draggable-list",attrs:{"list":_vm.template['LI'][item[0]].elements,"group":_vm.template['LI'][item[0]].group
                                                    ? _vm.template['LI'][item[0]].group
                                                    : { name: 'myGroup', pull: false, put: true }},on:{"change":function($event){return _vm.manipulateWidgets($event, 'LI', item[1].name, 'add')}}},_vm._l((_vm.template['LI'][item[0]].elements),function(value){return _c('div',{key:value.name,staticClass:"li-items-container",class:value.class,style:({
                                                    'background-color':
                                                        value.function_name == 'Games2'
                                                            ? '#4CBC9A'
                                                            : value.function_name == 'Scheduling2'
                                                            ? 'transparent'
                                                            : value.function_name == 'Web Links2'
                                                            ? '#5155C3'
                                                            : '',
                                                }),on:{"mouseover":function($event){_vm.hoveredElementId = 'LI' + index},"mouseleave":function($event){_vm.hoveredElementId = null},"click":function($event){value.function_type === 'generic'
                                                        ? _vm.addDetails(value.function_name, index, 'LI')
                                                        : ''}}},[(value.function_type == 'generic')?_c('img',{class:_vm.template['LI'][item[0]].title ? 'w-50 h-50' : 'w-90',staticStyle:{"border-radius":"0.5rem"},attrs:{"src":value.image,"alt":""}}):(value.function_type == '3rdParty')?_c('img',{staticClass:"w-50 h-50",staticStyle:{"border-radius":"0.5rem"},attrs:{"src":value.image,"alt":""}}):(value.function_name == 'Scheduling2')?_c('img',{staticStyle:{"max-width":"13rem","border-radius":"0.5rem"},attrs:{"src":value.image,"alt":""}}):_c('img',{style:({
                                                        width: value.function_name == 'Care Team2' ? '50%' : '70%',
                                                        'border-radius': '0.5rem',
                                                    }),attrs:{"src":value.image,"alt":""}}),(
                                                        value.function_type == 'generic' &&
                                                        _vm.template['LI'][item[0]].title
                                                    )?_c('p',{staticClass:"widget-title"},[_vm._v(" "+_vm._s(_vm.template['LI'][item[0]].title)+" ")]):(value.function_type == '3rdParty')?_c('p',{staticClass:"widget-title"},[_vm._v(" "+_vm._s(value.function_name)+" ")]):_vm._e(),(_vm.hoveredElementId == 'LI' + index)?[_c('div',{staticClass:"action-buttons-mhh w-100 h-100",staticStyle:{"border-radius":"35px"}},[_c('span',{staticClass:"background",attrs:{"id":"delete"},on:{"click":function($event){return _vm.manipulateWidgets(
                                                                    $event,
                                                                    'LI',
                                                                    item[1].name,
                                                                    'remove',
                                                                    value,
                                                                    index
                                                                )}}},[_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/px/delete-icon.png"),"alt":""}})]),(value.function_type === 'generic')?_c('div',[_c('span',{staticClass:"background",attrs:{"id":"edit"},on:{"click":function($event){return _vm.OpenEditModal(value.function_name, index, 'LI')}}},[_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/px/edit-icon.png"),"alt":""}})])]):_vm._e()])]:_vm._e()],2)}),0)],1)}),0),_c('div',{staticClass:"w-25 p-2 d-flex flex-column my-4",staticStyle:{"gap":"20px"}},_vm._l((_vm.callBtnItems),function(item,index){return _c('div',{key:item[1].name + '',class:_vm.width && _vm.height && !item[1].elements.length
                                                ? ' highlighted-section ' + item[1].class
                                                : '' + item[1].class,style:(_vm.callBtnItemsStyle(item, index))},[_c('draggable',{staticClass:"draggable-list",attrs:{"list":_vm.template['LI'][item[0]].elements,"group":_vm.template['LI'][item[0]].group
                                                    ? _vm.template['LI'][item[0]].group
                                                    : { name: 'myGroup', pull: false, put: true }},on:{"change":function($event){return _vm.manipulateWidgets($event, 'LI', item[1].name, 'add')}}},_vm._l((_vm.template['LI'][item[0]].elements),function(value){return _c('div',{key:value.name,staticClass:"li-items-container",class:value.class,style:({
                                                    'background-color':
                                                        value.function_name == 'Games2'
                                                            ? '#4CBC9A'
                                                            : value.function_name == 'Scheduling2'
                                                            ? 'transparent'
                                                            : value.function_name == 'Web Links2'
                                                            ? '#5155C3'
                                                            : '',
                                                }),on:{"mouseover":function($event){_vm.hoveredElementId = 'LI' + index + 4},"mouseleave":function($event){_vm.hoveredElementId = null},"click":function($event){value.function_type === 'generic'
                                                        ? _vm.addDetails(value.function_name, index + 4, 'LI')
                                                        : ''}}},[_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-center",class:index === 0 ? 'flex-column my-2' : '',staticStyle:{"gap":"5px"}},[(
                                                            value.function_type == 'generic' &&
                                                            _vm.template['LI'][item[0]].title
                                                        )?_c('img',{class:index !== 0 ? 'w-30' : 'w-30 h-50',staticStyle:{"border-radius":"0.5rem"},attrs:{"src":value.image,"alt":""}}):(value.function_type == '3rdParty')?_c('img',{staticClass:"w-30 h-50",staticStyle:{"border-radius":"0.5rem"},attrs:{"src":value.image,"alt":""}}):(
                                                            value.function_name === 'Mood Tracker' ||
                                                            value.function_name === 'Education'
                                                        )?_c('img',{style:({ 'max-width': index === 0 ? '70%' : '30%' }),attrs:{"src":value.image,"alt":""}}):_c('img',{style:({
                                                            width:
                                                                value.function_name == 'Care Team2'
                                                                    ? '50%'
                                                                    : index === 0
                                                                    ? '70%'
                                                                    : '30%',
                                                            'border-radius': '0.5rem',
                                                        }),attrs:{"src":value.image,"alt":""}}),(
                                                            value.function_type == 'generic' &&
                                                            _vm.template['LI'][item[0]].title
                                                        )?_c('p',{staticClass:"widget-title",style:(index !== 0 && {
                                                                'white-space': 'normal',
                                                                height: '40px',
                                                                display: '-webkit-box !important',
                                                                '-webkit-line-clamp': 2,
                                                                '-webkit-box-orient': 'vertical',
                                                                'max-width': '50%',
                                                            })},[_vm._v(" "+_vm._s(_vm.template['LI'][item[0]].title)+" ")]):(value.function_type == '3rdParty')?_c('p',{staticClass:"widget-title",style:(index !== 0 && {
                                                                'white-space': 'normal',
                                                                height: '40px',
                                                                display: '-webkit-box !important',
                                                                '-webkit-line-clamp': 2,
                                                                '-webkit-box-orient': 'vertical',
                                                                'max-width': '50%',
                                                            })},[_vm._v(" "+_vm._s(value.function_name)+" ")]):_vm._e()]),(_vm.hoveredElementId == 'LI' + index + 4)?[_c('div',{staticClass:"action-buttons-mhh w-100 h-100",staticStyle:{"border-radius":"35px"}},[_c('span',{staticClass:"background",attrs:{"id":"delete"},on:{"click":function($event){return _vm.manipulateWidgets(
                                                                    $event,
                                                                    'LI',
                                                                    item[1].name,
                                                                    'remove',
                                                                    value,
                                                                    index + 4
                                                                )}}},[_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/px/delete-icon.png"),"alt":""}})]),(value.function_type === 'generic')?_c('div',[_c('span',{staticClass:"background",attrs:{"id":"edit"},on:{"click":function($event){return _vm.OpenEditModal(
                                                                        value.function_name,
                                                                        index + 4,
                                                                        'LI'
                                                                    )}}},[_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/px/edit-icon.png"),"alt":""}})])]):_vm._e()])]:_vm._e()],2)}),0)],1)}),0),_c('div',{staticClass:"w-20 p-2 side-container"},_vm._l((Object.entries(_vm.template.GSI)),function(item,index){return _c('div',{key:item[1].name + '',class:_vm.width && _vm.height && !item[1].elements.length
                                                ? ' highlighted-section side-container-item ' + item[1].class
                                                : 'side-container-item ' + item[1].class,style:(item[1].style)},[_c('draggable',{staticClass:"draggable-list",attrs:{"list":_vm.template['GSI'][item[0]].elements,"group":_vm.template['GSI'][item[0]].group
                                                    ? _vm.template['GSI'][item[0]].group
                                                    : { name: 'myGroup', pull: false, put: true }},on:{"change":function($event){return _vm.manipulateWidgets($event, 'GSI', item[1].name, 'add')}}},_vm._l((_vm.template['GSI'][item[0]].elements),function(value){return _c('div',{key:value.name,class:value.class,style:({
                                                    'background-color':
                                                        value.function_name == 'Web Links2' ? '#5155C3' : '',
                                                    'border-radius': '35px',
                                                    position: 'relative',
                                                    display: 'flex',
                                                    'justify-content': 'center',
                                                    'align-items': 'center',
                                                    height: '100%',
                                                }),on:{"mouseover":function($event){_vm.hoveredElementId = 'GSI' + index},"mouseleave":function($event){_vm.hoveredElementId = null},"click":function($event){value.function_type === 'generic'
                                                        ? _vm.addDetails(value.function_name, index, 'GSI')
                                                        : ''}}},[(
                                                        value.function_type == 'generic' &&
                                                        _vm.template['GSI'][item[0]].title
                                                    )?_c('p',{staticClass:"widget-title d-flex justify-items-center align-items-center",staticStyle:{"white-space":"wrap","height":"40px","margin-left":"15px","display":"-webkit-box !important","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(_vm.template['GSI'][item[0]].title)+" ")]):(value.function_type == '3rdParty')?_c('p',{staticClass:"widget-title d-flex justify-items-center align-items-center",staticStyle:{"white-space":"wrap","height":"40px","margin-left":"15px","display":"-webkit-box !important","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(value.function_name)+" ")]):_vm._e(),(
                                                        value.function_type === '3rdParty' ||
                                                        (value.function_type === 'generic' &&
                                                            _vm.template['GSI'][item[0]].title)
                                                    )?_c('img',{staticClass:"mx-2",staticStyle:{"height":"50px","margin-right":"20px !important"},attrs:{"src":value.image,"alt":""}}):(value.function_type === '3rdParty')?_c('img',{staticStyle:{"max-width":"70%"},attrs:{"src":value.image,"alt":""}}):(value.function_name === 'Care Team2')?_c('img',{staticStyle:{"border-radius":"0.5rem","max-width":"40%"},attrs:{"src":value.image,"alt":""}}):(
                                                        value.function_name === 'Mood Tracker' ||
                                                        value.function_name === 'Education'
                                                    )?_c('img',{staticStyle:{"max-width":"70%","max-height":"85%"},attrs:{"src":value.image,"alt":""}}):_c('img',{staticStyle:{"max-width":"70%"},attrs:{"src":value.image,"alt":""}}),(_vm.hoveredElementId == 'GSI' + index)?[_c('div',{staticClass:"action-buttons-mhh w-100 h-100",staticStyle:{"border-radius":"35px"}},[_c('span',{staticClass:"background",attrs:{"id":"delete"},on:{"click":function($event){return _vm.manipulateWidgets(
                                                                    $event,
                                                                    'GSI',
                                                                    item[1].name,
                                                                    'remove',
                                                                    value,
                                                                    index
                                                                )}}},[_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/px/delete-icon.png"),"alt":""}})]),(value.function_type === 'generic')?_c('div',[_c('span',{staticClass:"background",attrs:{"id":"edit"},on:{"click":function($event){return _vm.OpenEditModal(value.function_name, index, 'GSI')}}},[_c('img',{staticStyle:{"height":"15px"},attrs:{"src":require("@/assets/px/edit-icon.png"),"alt":""}})])]):_vm._e()])]:_vm._e()],2)}),0)],1)}),0)])]),_vm._m(1)])])]):_vm._e()]),_c('div',{staticClass:"profile-delete-modal"},[(_vm.openConfirmModal)?_c('AlertChangeTemplate',{attrs:{"open":_vm.openConfirmModal},on:{"cancel":function($event){_vm.openConfirmModal = false},"handleChangeTemplate":_vm.handleChangeTemplate}}):_vm._e()],1),_c('div',{staticClass:"builddashboard"},[(_vm.openLauncherModal)?_c('DashboardBuilderModal',{attrs:{"open":_vm.openLauncherModal,"selected":_vm.selectedOption,"pageData":_vm.pageData},on:{"close":_vm.close,"selection-changed":_vm.updateSelectedOption}}):_vm._e()],1),_c('div',{staticClass:"addmoduledetail"},[(_vm.openEditModal)?_c('AddDetailModal',{attrs:{"open":_vm.openEditModal,"showSaveSetting":_vm.showSaveSetting,"clickedProfileFunction":_vm.clickedProfileFunction,"position":_vm.position,"pageData":_vm.pageData,"applicationFrame":_vm.applicationFrame,"current_template":_vm.current_template,"group":_vm.group},on:{"close":_vm.closedetail}}):_vm._e()],1),(_vm.isEducation)?_c('EducationFeedEditModal',{attrs:{"position":_vm.position,"applicationFrame":_vm.applicationFrame,"clickedProfileFunction":_vm.clickedProfileFunction,"pageData":_vm.pageData},on:{"cancel":function($event){_vm.isEducation = false}}}):_vm._e()],1),(_vm.loading)?_c('Loading'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5 py-4"},[_c('div',{staticClass:"d-flex justify-content-between w-100 align-items-center"},[_c('div',{staticClass:"d-flex w-70",staticStyle:{"gap":"1rem","align-items":"center"}},[_c('img',{staticStyle:{"width":"80px","height":"80px","border-radius":"50%","border":"2px solid #5155c3","padding":"0.2rem"},attrs:{"src":require("@/assets/photos/MHH-user.png"),"alt":""}}),_c('div',[_c('h1',{staticStyle:{"font-weight":"700","color":"#5155c3"}},[_vm._v(" Hello, "),_c('span',{staticStyle:{"color":"#4cbc9a"}},[_vm._v("Jaron William")])]),_c('h3',{staticStyle:{"color":"#271e4a"}},[_vm._v("Tuesday, November 28, 2023, 10:23 AM")])])]),_c('div',{staticClass:"w-30 d-flex",staticStyle:{"flex-direction":"row-reverse","gap":"2rem"}},[_c('div',{staticClass:"d-flex icon-container"},[_c('img',{attrs:{"src":require("@/assets/px/header-icons-images/logout.svg"),"alt":"","width":"30","height":"30"}}),_c('span',[_vm._v("Logout")])]),_c('div',{staticClass:"d-flex icon-container"},[_c('img',{attrs:{"src":require("@/assets/px/header-icons-images/notification.svg"),"alt":"","width":"30","height":"30"}}),_c('span',[_vm._v("Notifications")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 align-items-center",staticStyle:{"position":"absolute","bottom":"0","left":"0","border-radius":"10px 20px"}},[_c('img',{staticStyle:{"max-height":"4rem","border-radius":"10px 20px","width":"100%"},attrs:{"src":require("@/assets/px/drag-and-drop/footer.png"),"alt":""}})])}]

export { render, staticRenderFns }