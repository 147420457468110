<template>
    <div class="rounded-5 w-100 my-5">
        <div class="d-grid dashboard-builder w-50 px-4">
            <h1 style="font-weight: bold">Select Healthcare Provider</h1>
            <Multiselect
                v-if="this.$route.query.id != null"
                class="calendar-search-box radius-10"
                label="name"
                track-by="id"
                :disabled="true"
                :options="healthCareProviderOptions"
                :searchable="true"
                :placeholder="'Select Hospital / Healthcare Provider'"
                :show-labels="false"
                :value="healthCareProvider"
                @select="(e) => handleHealthCareSelection(e)"
                cursor: pointer
            >
                <template slot="noResult">
                    <div class="no-result-message">No members found</div>
                </template>
            </Multiselect>
            <Multiselect
                v-else
                class="calendar-search-box radius-10"
                label="name"
                track-by="id"
                :options="healthCareProviderOptions"
                :searchable="true"
                :placeholder="'Select Hospital / Healthcare Provider'"
                :show-labels="false"
                :value="healthCareProvider"
                @select="(e) => handleHealthCareSelection(e)"
                cursor: pointer
            >
                <template slot="noResult">
                    <div class="no-result-message">No members found</div>
                </template>
            </Multiselect>

            <span style="color: red; font-weight: bold">{{ errors.healthCareProvider }}</span>
        </div>

        <div v-if="this.healthCareProvider!=null" class="bg-white my-5 w-100 rounded-4">
            <h1 class="px-5 py-3 page-heading" style="background: #f1f7ff; font-weight: bold">
                Select an application
            </h1>
            <div class="d-flex w-100 justify-content-start py-4">
                
                <div @click="selectApp('kiosk')" class="d-grid w-20 justify-content-center " style="border-radius: 30px; background: #FFF; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10); margin-inline:20px; cursor: pointer;">
                    <div  class="d-flex justify-content-center my-4">
                        <span class="material-symbols-outlined"  v-if="deliverApplication != 'kiosk'" style="font-weight: bold; font-size: 30px; color: #5158C3;"> radio_button_unchecked </span>
                        <span class="material-symbols-outlined" v-if="deliverApplication == 'kiosk'" style="font-weight: bold; font-size: 30px; color: #5158C3;"> check_circle </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/dashboard2.png" alt="" class="img">
                    </div>
                    <div class="text-center py-4">
                        <span>Kiosk</span>
                    </div>
                </div>
                <div
                    @click="selectApp('mobex-health-hub')"
                    class="d-grid w-25 justify-content-center"
                    style="border-radius: 30px; background: #fff; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1) ; margin-inline:20px; cursor: pointer;"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'mobex-health-hub'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'mobex-health-hub'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/frame4.png" alt="" class="l-img" />
                    </div>
                    <div class="text-center py-4">
                        <span>Mobex Health Hub</span>
                    </div>
                </div>
                <div
                    @click="selectApp('family-connect')"
                    class="d-grid w-25 justify-content-center"
                    style="border-radius: 30px; background: #fff; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1) ; cursor: pointer;margin-inline: 20px;"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'family-connect'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'family-connect'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/frame5.png" alt="" class="l-img" />
                    </div>
                    <div class="text-center py-4">
                        <span>Family Connect</span>
                    </div>
                </div>

                <div
                    v-if="isDefaultCompany"
                    @click="selectApp('star-kid')"
                    class="d-grid w-20 justify-content-center"
                    style="border-radius: 30px; background: #fff; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1) ; cursor: pointer;margin-inline: 20px;"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'star-kid'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'star-kid'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/starkid.png" alt="" class="img" />
                    </div>
                    <div class="text-center py-4">
                        <span>Star Kid</span>
                    </div>
                </div>
                <div
                    v-if="isDefaultCompany"
                    @click="selectApp('mobex-health-plus')"
                    class="d-grid w-20 justify-content-center"
                    style="border-radius: 30px; background: #fff; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1) ; cursor: pointer;margin-inline: 20px;"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'mobex-health-plus'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'mobex-health-plus'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/demoAppDashboard.png" alt="" class="img" />
                    </div>
                    <div class="text-center py-4">
                        <span>MobexHealth Plus</span>
                    </div>
                </div>

            </div>
            <span style="color: red; font-weight: bold">{{ errors.applicationFrame }}</span>
        </div>
       
        <!-- <div v-if="this.$route.query.id" class="bg-white my-5 w-100 rounded-4">
            <h1 class="px-5 py-3 page-heading" style="background: #f1f7ff; font-weight: bold">
             Select Template
            </h1>
            <div class="d-flex w-100 justify-content-start py-4">
            
                <div
                    @click="handletemplateType(templateTypes[0])"
                    class="d-grid w-25 justify-content-center"
                    style="border-radius: 30px; background: #fff; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1); cursor: pointer;"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="templateTypeData==null || templateTypeData.name != 'Maternal Application'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="templateTypeData!=null && templateTypeData.name == 'Maternal Application'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/frame6.png" alt="" class="img" />
                    </div>
                    <div class="text-center py-4">
                        <span>Maternal Application</span>
                    </div>
                </div>
                <div @click="handletemplateType(templateTypes[1])" class="d-grid w-25 justify-content-center " style="border-radius: 30px; background: #FFF; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10); margin-inline:20px;cursor: pointer;">
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="templateTypeData==null || templateTypeData.name != 'Mobex CDE App'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="templateTypeData!=null && templateTypeData.name == 'Mobex CDE App'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/dashboard2.png" alt="" class="img">
                    </div>
                    <div class="text-center py-4">
                        <span>Mobex CDE App</span>
                    </div>
                </div>
            
                </div>
            <span style="color: red; font-weight: bold">{{ errors.templateType }}</span>
        </div> -->

        <div v-if="this.healthCareProvider!=null" class="bg-white w-100 rounded-4">
            <h1 class="px-5 py-3 page-heading" style="background: #f1f7ff; font-weight: bold" v-if="this.applicationFrame!=null">
             Select Dashboard
            </h1>
            <!-- <div class="d-flex w-100 justify-content-start py-4"> -->
            
                <!-- <div @click="openTemplateDetailModal()" class="d-grid w-25 justify-content-center" style="border-radius:20px; height:25rem;border-style: dotted; border-width:2px; border-color: #5155C3; background: #F1F7FF; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);cursor: pointer;">
                    
                    <div class="d-flex justify-content-center align-items-center">
                        <img src="@/assets/px/drag-and-drop/new.png" alt="" class="s-img" />
                    </div>
                    <div class="text-center py-4">

                       
                        <div  v-if="this.$route.query.id"><span style="color: #5057C3; font-size:1rem;  line-height: 15px; word-wrap: break-word">Edit<br/></span><span style="color: #5057C3; font-size:1.5rem;  font-weight: 500; line-height: 19px; word-wrap: break-word"> Dashboard</span></div>
                        <div v-else style="text-align: center"><span style="color: #5057C3; font-size:1rem;  line-height: 15px; word-wrap: break-word">Create New<br/></span><span style="color: #5057C3; font-size:1.5rem;  font-weight: 500; line-height: 19px; word-wrap: break-word"> Dashboard</span></div>
                    </div>
                </div> -->
                <!-- </div> -->
            <!-- <span style="color: red; font-weight: bold">{{ errors.templateType }}</span> -->
            <TemplateDetails v-if="applicationFrame!=null"   :templateType="templateType" :pageData="pageData" :errors="errors" :applicationFrame="applicationFrame" @capturetemplateName="capturetemplateName" :dashboardName="dashboardName"/>
        </div>

        <Loading v-if="loading" />

    </div>
</template>
<script>
import { dashboardBuilder } from '@/util/apiRequests';
import TemplateDetails from './template_details.vue'
import Loading from '@/components/general/loading/loading.vue';
import { companies } from '@/util/apiRequests';
export default {
    name: 'SelectCompanyApplication',
    components: {
        TemplateDetails,
        Loading
    },
    props: {
        errors: {
            type: Object,
        },
        pageData: {
            type: Object,
        },
        healthCareProviderData: {
            type: Object,
        },
        templateType:{
            type:Object
        },
        applicationFrame: {
            type: String,
        },
        dashboardName:{
            type:String
        }
    },
    data() {
        return {
            // healthCareProvider: { id: '1', name: 'Mobex Health Hub' },
            // deliverApplication: 'mobex-health-hub',
            healthCareProvider: null,
            templateTypeData:null,
            deliverApplication: null,
            healthCareProviderOptions: [],
            templateTypes:[
            { id: '1', name: 'Maternal Application' },
            { id: '2', name: 'Mobex CDE App' },
            { id: '3', name: 'Mobex Health Hub' },
            { id:'4',name:'Family Connect'}
            ],
            builderPageNo: this.pageNo,
            openTemplateModal:false,
            loading: true,
        };
    },
    computed: {
        isDefaultCompany() {
            return this.$store.state.defaultCompanyId == this.$store.state.user.company_id;
        },
    },
    methods: {
       async capturetemplateName(event){
        const { page1} = event;
        if(page1)
        {
           
            const {  templateType, dashboardName} = page1;
            this.$emit('captureValues', { page1: { templateType, dashboardName} });

        }
           
        },
        openTemplateDetailModal()
        {
            this.openTemplateModal=true;
        },
        close()
        {
            
            this.openTemplateModal=false;
        },
        handleHealthCareSelection(e) {        
            this.healthCareProvider = e;
            this.$emit('captureValues', { page1: { healthCareProvider: e } });
        },
        handletemplateType(e) {          
            this.templateTypeData = e;
            this.$emit('captureValues', { page1: { templateType: e } });
        },
        selectApp(app) {
            this.deliverApplication = app;
            if(this.deliverApplication=='mobex-health-hub')
            {
                
               // this.$emit('captureValues',{pageData:{template:null,templateStyles:null,templateData:null}})
                this.$emit('captureValues', { page1: { applicationFrame: app , templateType:null } });
            }
            else
            {
               // this.$emit('captureValues',{pageData:{template:null,templateStyles:null,templateData:null}})
                this.$emit('captureValues', { page1: { applicationFrame: app  } });

            }
           this.errors.applicationFrame = null;
        },



        async getTemplateList() {
            let list = await this.$api.get(dashboardBuilder.getTemplateList('completed'));
            let data = list.data.templateMetaList;
            let temp = [];
            data.forEach((element) => {
                temp.push(JSON.parse(element.health_care_provider).name);
            });
            let removedArray = this.healthCareProviderOptions.filter(function (el) {
                return !temp.includes(el.name);
            });
          
            this.healthCareProviderOptions = removedArray;
        },
        async getCompany() {
                try {
                    let result = await this.$api.get(companies.getCompanyInfo());
                    let temp = [];
                    temp.push({ id: '1', name:result.data[0].company_name})
                    this.healthCareProviderOptions = temp;
                    this.handleHealthCareSelection({ id: '1', name:result.data[0].company_name});
                } catch (err) {
                    console.log(err);
                }
            }
    },

    watch: {},
    async created() {

        window.scrollTo(0,0);

        //this.getTemplateList();
        await this.getCompany();
        this.templateTypeData=this.templateType;
        this.deliverApplication = this.applicationFrame;
        this.loading = false;
    },
};
</script>

<style scoped>
.page-heading {
    background: #f1f7ff;
    font-weight: 700;
    border-radius: 27px 27px 0px 0px;
}
@media only screen and (max-width: 2000px) {
    .img {
        width: 200px;
        height: 350px;
    }

    .l-img {
        width: 320px;
        height: 230px;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 2000px) {
    .img {
        width: 150px;
        height: 250px;
    }

    .l-img {
        width: 280px;
        height: 200px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .img {
        width: 130px;
        height: 230px;
    }

    .l-img {
        width: 230px;
        height: 180px;
    }
}

@media only screen and (max-width: 1200px) {
    .img {
        width: 100px;
        height: 200px;
    }

    .l-img {
        width: 180px;
        height: 100px;
    }
}
.s-img{
    max-width: 60%;
}
</style>