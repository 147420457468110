<template>
    <div class="rounded-5 w-100 my-5">
        <div class="d-flex justify-content-between ">
                <div class="bg-white rounded-4 border mx-2 widget-list">
                    <h1 class="px-5 py-3 page-heading"
                        style="background: #F1F7FF; font-weight: bold; border-radius: 14px 14px 0 0;">Selected Modules widgets
                    </h1>
                    <draggable class="draggable-list d-grid widgets-list w-90 p-4"
                        style="grid-template-columns: repeat( 2, 1fr); gap: 1.5%; overflow: auto;"
                        :list="widgetsList" :group="{ name: 'myGroup', pull: true, put: false }" @start="onDragStart"
                        @end="onDragEnd">
                    <div v-for="element in widgetsList" :key="element.id">
                        <img :src="element.image" alt="">
                    </div>
                </draggable>
            </div>
            <div class="bg-white rounded-4 border mx-2 dash-builder">
                <div class="d-flex justify-content-between align-items-center"
                    style="background: #F1F7FF ; border-radius: 14px 14px 0 0;">
                    <h1 class="px-5 py-3 page-heading" style="font-weight: bold">Dashboard Builder {{ selectedOption }}</h1>
                </div>
                <div class='droppable-template' v-if="templateStyles">
                    <div :style="templateStyles.primeContainer">
                        <div class="px-5 py-4">
                            <div class="d-flex justify-content-between w-100 align-items-center">
                                <div class="d-grid w-33">
                                    <h1 style="font-weight: lighter;">Good Morning , Alfonso</h1>
                                    <h3 style="font-weight: bold;">Wednesday, February 28, 2023</h3>
                                </div>          
                            </div>
                        </div>
                        <div class='t-m-c' :style="templateStyles.templateMainContainer">
                            <div class='u-l-c' :style="templateStyles.upperLayoutContainer">
                                <div v-for="(item, index) in Object.entries(template.LI)"
                                    :class="(template['LI'][item[0]].elements.length>0 ? 'removeColor' : '')"
                                    :style="templateStyles.upperLayoutContainerItemCommon + item[1].style"
                                    :key="item[1].name + ''">
                                    <draggable class="draggable-list" :list="template['LI'][item[0]].elements"
                                        v-if="index >= 0"
                                        :class="(item[1].width == width && item[1].height == height) ? ' highlighted-section ' + item[1].class : '' + item[1].class" 
                                        :group="template['LI'][item[0]].group ? template['LI'][item[0]].group : { name: 'myGroup', pull: false, put: true }"
                                        @change="manipulateWidgets($event, 'LI', item[1].name, 'add')"
                                        :options="{ accept: false }">
                                        <div :class="value.class" v-for="value in template['LI'][item[0]].elements"
                                            :key="value.name" style="position: relative;"
                                            @mouseover="hoveredElementId = value.id" @mouseleave="hoveredElementId = null">
                                            <img :src="value.image" alt="" class="w-100 h-100">
                                            <template v-if="hoveredElementId == value.id">
                                                <div class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                                                    style=" top: 0; background: #F1F7FF; opacity: .7;">
                                                    <span class="material-symbols-outlined cursor-pointer"
                                                        style="color: #fd0000; font-size: 40px; font-weight: bold;"
                                                        @click="manipulateWidgets($event, 'LI', item[1].name, 'remove', value)">
                                                        delete
                                                    </span>
                                                </div>
                                            </template>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div :style='templateStyles.footerContainer' class="d-flex justify-content-between w-100 align-items-center h-200 ">
                               <img src="@/assets/px/drag-and-drop/bottom_bar.png" alt="" style="height:4rem;" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-delete-modal">
                <AlertChangeTemplate v-if="openConfirmModal" :open="openConfirmModal" @cancel="openConfirmModal = false"
                    @handleChangeTemplate="handleChangeTemplate">
                </AlertChangeTemplate>
            </div>
            <div class="builddashboard">
                <DashboardBuilderModal v-if="openLauncherModal" :open="openLauncherModal" @close="close"
                    :selected="selectedOption" @selection-changed="updateSelectedOption" :pageData="pageData" />
            </div>
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
import { dashboardBuilder, memberProfile } from '@/util/apiRequests';
import draggable from "vuedraggable";
import DashboardBuilderModal from '@/components/dashboard/DashboardBuilderModal.vue';
import AlertChangeTemplate from './ChangeTemplateAlert.vue';
import Loading from '@/components/general/loading/loading.vue';
export default {
    name: "BuildFamilyConnectDashboard",
    components: {
        draggable,
        DashboardBuilderModal,
        AlertChangeTemplate,
        Loading
    },
    props: {
        pageData: {
            type: Object
        },
        current_template: {
            type: Number
        },
        applicationFrame: {
            type: String
        }
    },
    data() {
        return {
            selectedOption: this.current_template,
            templateStyles: null,
            template: null,
            widgetsList: [],
            // widgetsList:[
            //     { id: '1', name: "Appts", height: 120, width: 220, class: 'list-item removed', image: '/img/appts.644b7b75.png' },
            //     { id: '2', name: "Care Team", height: 130, width: 230, class: 'list-item removed', image: '/img/care-team.411859a5.png' },
            //     { id: '3', name: "Content Education", height: 'a', width: 'b', class: 'list-item removed', image: '/img/content-education.dcef363c.png' },
            //     { id: '4', name: "Entertainment", height: 'a', width: 'b', class: 'list-item removed', image: '/img/entertainment.e23cae10.png' },
            //     { id: '5', name: "Let Connect", height: 'a', width: 'b', class: 'list-item removed', image: '/img/let-connect.fedd225b.png' },
            //     { id: '6', name: "Medicine", height: 140, width: 240, class: 'list-item removed', image: '/img/medicine-older.596b7083.png' },
            //     { id: '7', name: "Photos", height: 'a', width: 'b', class: 'list-item removed', image: '/img/photos.5fcf05e6.png' },
            //     { id: '8', name: "Vitals", height: 140, width: 240, class: 'list-item removed', image: '/img/vital.8d4f2589.png' },
            //     { id: '9', name: "Web Links", height: 'a', width: 'b', class: 'list-item removed', image: '/img/web-links.0b45491a.png' },
            // ],
            isHighlighted: false,
            inp: this.value,
            ref_span: null,
            openLauncherModal: false,
            openConfirmModal: false,
            templateData: {},
            templateDataList: [],
            height: '',
            width: '',
            showButtonsLI: [],
            showButtonsGSI: [],
            hoveredElementId: null,
            allWidgets: [],
            loading: true,
        };
    },
    methods: {
        showButton(index, label) {
            if (label == 'LI') {
                this.$set(this.showButtonsLI, index, true);
            }
            else {
                this.$set(this.showButtonsGSI, index, true);
            }
        },
        hideButton(index, label) {
            if (label == 'LI') {
                this.$set(this.showButtonsLI, index, false);
            }
            else {
                this.$set(this.showButtonsGSI, index, false);
            }
        },
        handleChangeTemplate() {
            this.openLauncherModal = true;
            this.openConfirmModal = false;
        },
        async getTemplateMetaData() {
            let metaData = await this.$api.get(dashboardBuilder.getTemplateMeta(this.selectedOption));
            let templateMeta = JSON.parse(metaData.data.templateMeta);
            let _template = templateMeta.templateItems;
            const GSIElement = _template['GSI']['GSI 1'];
            _template['GSI'] = {};
            const GSIs = this.allWidgets.filter(el => el.height == 'a' && el.width == 'b')
            let _GSIElement = {};
            for (const i in GSIs) {
                _GSIElement['GSI ' + (i + 1)] = {
                    ...GSIElement,
                    name: "GSI " + (i + 1)
                };
            }
            _template['GSI'] = JSON.parse(JSON.stringify(_GSIElement));
            this.template = _template;
            Object.keys(this.template).forEach(group => {
                let groupElements = Object.keys(this.template[group])
                this.templateData[group] = {}
                groupElements.forEach(groupEl => {
                    this.templateData[group][groupEl] = undefined;
                })
            });
            this.templateStyles = templateMeta.templateCommonStyles;
            this.$emit('captureValues', { 'page4': { 'template': this.template, 'templateStyles': this.templateStyles } });
            return true;
        },
        manipulateWidgets(event, section, listName, operation, element) {
            if (operation === 'remove') {
                element.class = 'list-item removed';
                this.widgetsList.unshift(element);
                this.template[section][listName].elements.pop();
                this.templateData[section][listName] = undefined;
                this.$set(this.template[section][listName], 'group', undefined);
            }
            else {
                let newlyAdded = operation === 'add' ? event.added.element : null;
                if (!newlyAdded.function_type) {
                    delete newlyAdded.company_id;
                    delete newlyAdded.created_at;
                    delete newlyAdded.updated_at;
                }
                newlyAdded['function_type'] = !newlyAdded.function_type || newlyAdded.function_type == 'core' ? 'core' : '3rdParty';
                if (operation === 'add') {
                    if (this.template[section][listName].elements.length > 1) {
                        this.template[section][listName].elements.splice(1);
                        newlyAdded.class = 'list-item removed';
                        this.widgetsList.unshift(newlyAdded);
                    }
                    else if (newlyAdded.width != this.template[section][listName].width && newlyAdded.height != this.template[section][listName].height) {
                        this.template[section][listName].elements.splice(0);
                        newlyAdded.class = 'list-item removed';
                        this.widgetsList.unshift(newlyAdded);
                    }
                    else {
                        this.$set(this.template[section][listName], 'group', { name: 'myGroup', pull: false, put: false });
                        this.templateData[section][listName] = newlyAdded;
                    }
                }
            }
            this.$emit('captureValues', { 'page4': { templateData: this.templateData } });
        },
        async updateSelectedOption(option) {
            if (this.selectedOption != option) {
                this.selectedOption = option;
                this.$emit('selection-changed', this.selectedOption);
                await this.getTemplateMetaData();
                await this.getWidgets();
                this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
            }
        },
        onDragStart(event) {
            let element = event.item._underlying_vm_;
            this.height = element.height;
            this.width = element.width;
        },
        onDragEnd() {
            this.height = null;
            this.width = null;
        },
        close() {
            this.openLauncherModal = false;
        },
        async getWidgets() {
            const response = await Promise.all([this.getCoreWidgets(), this.get3rdPartyFunctionWidgets()])
            this.widgetsList = this.widgetsList.concat(response[0]);
            this.widgetsList = this.widgetsList.concat(response[1]);
        },
        async getCoreWidgets() {
            const requestData = {
                functionNames: this.pageData.page2.coreFunctions.map(el => el.function_name),
                application:this.applicationFrame
            }
            const endpoint = dashboardBuilder.getWidgets();
            let response = await this.$api.post(endpoint, requestData);
            return response.data.data;
        },
        async get3rdPartyFunctionWidgets() {
            if (this.pageData.page2.thirdPartyFunctions) {
                const requestData = {
                    functionIds: this.pageData.page2.thirdPartyFunctions.map(el => el.id)
                }
                const endpoint = memberProfile.getFunctionDetailsByIds();
                let response = await this.$api.post(endpoint, requestData);
                let thirdPartyWidgets = response.data.data.map(el => {
                    let data = {
                        ...el,
                        height: 'a',
                        width: 'b'
                    }
                    return data;
                })
                return thirdPartyWidgets;
            }
            else {
                return [];
            }
        }
    },
    watch: {
    },
    async created() {

        window.scrollTo(0,0);

        await this.getWidgets();
        const widgets = this.widgetsList.map(el => el.id)
        this.allWidgets = this.widgetsList;
        const widgetsToRemoveIndexs = [];
        if (this.pageData.page4 && this.pageData.page4.template && this.pageData.page4.templateStyles && this.pageData.page4.templateData) {
            const { template, templateData, templateStyles } = this.pageData.page4;
            if (template) {
                this.template = template;
            }
            if (templateStyles) {
                this.templateStyles = templateStyles;
            }
            if (templateData) {
                this.templateData = templateData;
            }
           
            Object.keys(this.template).forEach(parentEl => {
                if (!this.template[parentEl]) {
                    this.templateData[parentEl] = {};
                }
                Object.keys(this.template[parentEl]).forEach(childEl => {
                    if (!this.templateData[parentEl][childEl]) {
                        this.templateData[parentEl][childEl] = {};
                    }
                    if (this.templateData[parentEl][childEl]) {
                        let i = widgets.indexOf(this.templateData[parentEl][childEl].id);
                        if (i > -1) {
                            this.templateData[parentEl][childEl].image = this.widgetsList[i].image;
                            this.$set(this.template[parentEl][childEl], 'elements', [this.templateData[parentEl][childEl]]);
                            widgetsToRemoveIndexs.push(this.templateData[parentEl][childEl].id);
                            this.$set(this.template[parentEl][childEl], 'group', { name: 'myGroup', pull: false, put: false });
                        }
                        else {
                            this.$set(this.template[parentEl][childEl], 'elements', []);
                            this.templateData[parentEl][childEl] = undefined;
                            this.$set(this.template[parentEl][childEl], 'group', { name: 'myGroup', pull: false, put: true });
                        }
                    }
                    else {
                        this.$set(this.template[parentEl][childEl], 'elements', []);
                        this.$set(this.template[parentEl][childEl], 'group', { name: 'myGroup', pull: false, put: true });
                    }
                })
            });
            widgetsToRemoveIndexs.forEach(id => {
                const i = this.widgetsList.findIndex(el => el.id == id);
                this.widgetsList.splice(i, 1);
            })
        }
        else {
            await this.getTemplateMetaData();
           
        }

        this.loading = false;
    },
};
</script>
<style>
.hover-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -180%);
}
.dashboard-builder-template {
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1500px;
    gap: 2rem;
    padding: 2rem;
}
.dashboard-builder-template>div {
    border-radius: 1rem !important;
    box-sizing: border-box;
}
.widget-list {
    width: 45%;
    min-width: 18rem;
    border: 1px solid rgb(193, 187, 187);
    display: flex;
    flex-direction: column;
}
.widget-list-header {
    /* padding: 2rem; */
    background-color: rgb(214, 230, 235);
    font-weight: 700;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem;
    /* display: none!important; */
}
::-webkit-scrollbar {
    width: 100%;
    /* height: 12; */
}
::-webkit-scrollbar-track {
    background: rgba(103, 97, 97, 0.95);
    border-radius: 1.5rem;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(219, 205, 205, 0.6);
    border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(212, 203, 203, 0.9);
}
.widgets-list .widget-item {
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    background: #27a2d3;
    display: inline-block;
}
.draggable-list {
    height: 100%;
    width: 100%;
}
.highlighted-section {
    border: 4px dotted #1fcf25;
}
.list-item {
    height: 100%;
    width: 100%;
    /* background: lightblue; */
}
.removeColor{
    background-color: transparent !important;
}
</style>