<template>
    <div class="page-body add-svg-modal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app section-body">
                <div
                    class="modal-content-header modal-list-third-party-app d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
                >
                    <div class="w-30 d-flex justify-content-between align-items-center">
                        <span class="label-app-list-header">Configure QR</span>
                    </div>
                </div>

                <ValidationObserver ref="form">
                    <form style="box-shadow: none" class="p-0">
                        <div class="my-3">
                            <span class="field-container d-flex align-items-center mt-2 mb-4 w-80">
                                <label class="w-50 me-3 d-flex add-new-app-label" style="justify-content: end"
                                    >Position*</label
                                >
                                <Multiselect
                                    track-by="type"
                                    label="name"
                                    class="position-relative"
                                    placeholder="Choose Categories"
                                    :options="positionList"
                                    v-model="positionInfo"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="handlePosition"
                                    required
                                />
                            </span>
                            <span class="field-container d-flex align-items-center mt-2 mb-4 w-80">
                                <label for="disclaimer" class="w-50 me-3 d-flex add-new-app-label justify-content-end"
                                    >Message*</label
                                >
                                <textarea
                                    placeholder="Write Message"
                                    name="description"
                                    id="description"
                                    cols="50"
                                    rows="3"
                                    class="input-text position-relative"
                                    style="border-radius: 8px !important"
                                    v-model="functionInfo.message"
                                    :disabled="functionInfo.position === 'none'"
                                    required
                                />
                            </span>
                        </div>

                        <div class="section-footer add-new-app-form mt-4">
                            <div class="d-flex justify-content-center" style="bottom: 10%">
                                <button type="button" class="primary btn-save" @click="save">Save</button>
                                <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';

    const positionList = [
        {
            type: 'none',
            name: 'None',
        },
        {
            type: 'top-left',
            name: 'Top Left',
        },
        {
            type: 'top-right',
            name: 'Top Right',
        },
        {
            type: 'bottom-left',
            name: 'Bottom Left',
        },
        {
            type: 'bottom-right',
            name: 'Bottom Right',
        },
    ];

    export default {
        name: 'ConfigureQRModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            screenSaverData: {
                type: Object,
            },
        },
        data() {
            return {
                positionList,
                positionInfo: {
                    type: 'none',
                    name: 'None',
                },
                functionInfo: {
                    position: 'none',
                    message: '',
                },
            };
        },
        methods: {
            handlePosition(event) {
                if (this.functionInfo.position === 'none') {
                    this.functionInfo.message = 'Scan Here to take me Home!';
                } else if (event.type === 'none') {
                    this.functionInfo.message = '';
                }
                this.functionInfo.position = event.type;
            },
            async save() {
                if (this.functionInfo.type !== 'none' && this.functionInfo.message === '') {
                    return this.$toasted.error('Please fill all required fields');
                }
                this.$emit('save', this.functionInfo);
            },
        },
        created() {
            if (this.screenSaverData) {
                this.functionInfo = { ...this.screenSaverData };
                this.positionInfo = this.positionList.find((item) => item.type === this.functionInfo.position);
            }
        },
    };
</script>

<style scoped>
    .modal-content-header {
        font-size: 18px;
        color: #2e3343;
        margin: 0 0 20px;
        background-color: #f1f7ff;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .add-svg-modal .modal__content {
        padding: 0;
        border-radius: 10px;
    }
</style>
