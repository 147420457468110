<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal">
        <div class="modal-list-third-party-app" v-if="showAppListing">
            <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                <span
                    id="target"
                    class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle"
                    role="button"
                    @click="cancel"
                    >close</span
                >
            </ejs-tooltip>

            <div class="preview-body">
                <div class="droppable-template" v-if="pageData.page4.templateStyles">
                    <div :style="pageData.page4.templateStyles.primeContainer" class="w-100">
                        <div :style="pageData.page4.templateStyles.bgImageContainer">
                            <img
                                :style="pageData.page4.templateStyles.bgImage"
                                src="@/assets/px/drag-and-drop/MHH-FC-Background.png"
                                alt=""
                                width="100"
                                height="100"
                            />
                        </div>
                        <div :style="pageData.page4.templateStyles.primeContainer" class="w-100">
                            <div class="px-5 py-4">
                                <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex w-70" style="gap: 1rem; align-items: center">
                                        <img
                                            src="@/assets/photos/MHH-user.png"
                                            alt=""
                                            style="
                                                width: 80px;
                                                height: 80px;
                                                border-radius: 50%;
                                                border: 2px solid #5155c3;
                                                padding: 0.2rem;
                                            "
                                        />
                                        <div>
                                            <h1 style="font-weight: 700; color: #5155c3">
                                                Hello, <span style="color: #4cbc9a">Jaron William</span>
                                            </h1>
                                            <h3 style="color: #271e4a">Tuesday, November 28, 2023, 10:23 AM</h3>
                                        </div>
                                    </div>
                                    <div class="w-30 d-flex" style="flex-direction: row-reverse; gap: 2rem">
                                        <div class="d-flex icon-container">
                                            <img
                                                src="@/assets/px/header-icons-images/logout.svg"
                                                alt=""
                                                width="30"
                                                height="30"
                                            />
                                            <span>Logout</span>
                                        </div>
                                        <div class="d-flex icon-container">
                                            <img
                                                src="@/assets/px/header-icons-images/notification.svg"
                                                alt=""
                                                width="30"
                                                height="30"
                                            />
                                            <span>Notifications</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="t-m-c w-100" :style="pageData.page4.templateStyles.templateMainContainer">
                                <div class="u-l-c w-100 d-flex h-100" style="gap: 10px; margin-bottom: 50px">
                                    <div
                                        class="w-50 px-2"
                                        :style="pageData.page4.templateStyles.upperLayoutContainer"
                                        style="
                                            align-items: center;
                                            place-items: center;
                                            display: grid;
                                            grid-template-columns: repeat(2, 1fr);
                                            grid-template-rows: repeat(2, 1fr);
                                        "
                                    >
                                        <div
                                            v-for="(item, index) in moduleItems"
                                            :key="item[1].name + ''"
                                            :style="moduleItemsStyle(item)"
                                        >
                                            <draggable
                                                class="draggable-list"
                                                :list="item.elements"
                                                :group="{ name: 'myGroup', pull: false, put: true }"
                                                @change="manipulateWidgets($event, 'LI', item[1].name, 'add')"
                                            >
                                                <div
                                                    :class="value.class"
                                                    v-for="value in pageData.page4.template['LI'][item[0]].elements"
                                                    :key="value.name"
                                                    @mouseover="hoveredElementId = 'LI' + index"
                                                    @mouseleave="hoveredElementId = null"
                                                    @click="
                                                        value.function_type === 'generic'
                                                            ? addDetails(value.function_name, index)
                                                            : ''
                                                    "
                                                    class="li-items-container"
                                                    :style="{
                                                        'background-color':
                                                            value.function_name == 'Games2'
                                                                ? '#4CBC9A'
                                                                : value.function_name == 'Scheduling2'
                                                                ? 'transparent'
                                                                : value.function_name == 'Web Links2'
                                                                ? '#5155C3'
                                                                : '',
                                                    }"
                                                >
                                                    <img
                                                        v-if="value.function_type == 'generic'"
                                                        :src="value.image"
                                                        alt=""
                                                        :class="
                                                            pageData.page4.template['LI'][item[0]].title
                                                                ? 'w-50 h-50'
                                                                : 'w-90'
                                                        "
                                                        style="border-radius: 0.5rem"
                                                    />
                                                    <img
                                                        v-else-if="value.function_type == '3rdParty'"
                                                        :src="value.image"
                                                        alt=""
                                                        class="w-50 h-50"
                                                        style="border-radius: 0.5rem"
                                                    />
                                                    <img
                                                        v-else-if="value.function_name == 'Scheduling2'"
                                                        :src="value.image"
                                                        alt=""
                                                        style="max-width: 13rem; border-radius: 0.5rem"
                                                    />
                                                    <img
                                                        v-else
                                                        :src="value.image"
                                                        alt=""
                                                        :style="{
                                                            width: value.function_name == 'Care Team2' ? '50%' : '70%',
                                                            'border-radius': '0.5rem',
                                                        }"
                                                    />

                                                    <p
                                                        v-if="
                                                            value.function_type == 'generic' &&
                                                            pageData.page4.template['LI'][item[0]].title
                                                        "
                                                        class="widget-title"
                                                    >
                                                        {{ pageData.page4.template['LI'][item[0]].title }}
                                                    </p>
                                                    <p
                                                        v-else-if="value.function_type == '3rdParty'"
                                                        class="widget-title"
                                                    >
                                                        {{ value.function_name }}
                                                    </p>
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>

                                    <div class="w-25 p-2 d-flex flex-column my-4" style="gap: 20px">
                                        <div
                                            v-for="(item, index) in callBtnItems"
                                            :key="item[1].name + ''"
                                            :class="item[1].class"
                                            :style="callBtnItemsStyle(item, index)"
                                        >
                                            <draggable
                                                class="draggable-list"
                                                :list="pageData.page4.template['LI'][item[0]].elements"
                                                :group="
                                                    pageData.page4.template['LI'][item[0]].group
                                                        ? pageData.page4.template['LI'][item[0]].group
                                                        : { name: 'myGroup', pull: false, put: true }
                                                "
                                                @change="manipulateWidgets($event, 'LI', item[1].name, 'add')"
                                            >
                                                <div
                                                    v-for="value in pageData.page4.template['LI'][item[0]].elements"
                                                    :class="value.class"
                                                    :key="value.name"
                                                    @mouseover="hoveredElementId = 'LI' + index + 4"
                                                    @mouseleave="hoveredElementId = null"
                                                    @click="
                                                        value.function_type === 'generic'
                                                            ? addDetails(value.function_name, index + 4, 'LI')
                                                            : ''
                                                    "
                                                    class="li-items-container"
                                                    :style="{
                                                        'background-color':
                                                            value.function_name == 'Games2'
                                                                ? '#4CBC9A'
                                                                : value.function_name == 'Scheduling2'
                                                                ? 'transparent'
                                                                : value.function_name == 'Web Links2'
                                                                ? '#5155C3'
                                                                : '',
                                                    }"
                                                >
                                                    <div
                                                        class="w-100 h-100 d-flex align-items-center justify-content-center"
                                                        :class="index === 0 ? 'flex-column my-2' : ''"
                                                        style="gap: 5px"
                                                    >
                                                        <img
                                                            v-if="
                                                                value.function_type == 'generic' &&
                                                                pageData.page4.template['LI'][item[0]].title
                                                            "
                                                            :src="value.image"
                                                            alt=""
                                                            :class="index !== 0 ? 'w-30' : 'w-30 h-50'"
                                                            style="border-radius: 0.5rem"
                                                        />
                                                        <img
                                                            v-else-if="value.function_type == '3rdParty'"
                                                            :src="value.image"
                                                            alt=""
                                                            class="w-30 h-50"
                                                            style="border-radius: 0.5rem"
                                                        />
                                                        <img
                                                            v-else-if="
                                                                value.function_name === 'Mood Tracker' ||
                                                                value.function_name === 'Education'
                                                            "
                                                            :src="value.image"
                                                            alt=""
                                                            :style="{ 'max-width': index === 0 ? '70%' : '30%' }"
                                                        />
                                                        <img
                                                            v-else
                                                            :src="value.image"
                                                            alt=""
                                                            :style="{
                                                                width:
                                                                    value.function_name == 'Care Team2'
                                                                        ? '50%'
                                                                        : index === 0
                                                                        ? '70%'
                                                                        : '30%',
                                                                'border-radius': '0.5rem',
                                                            }"
                                                        />
                                                        <p
                                                            v-if="
                                                                value.function_type == 'generic' &&
                                                                pageData.page4.template['LI'][item[0]].title
                                                            "
                                                            class="widget-title"
                                                            :style="
                                                                index !== 0 && {
                                                                    'white-space': 'normal',
                                                                    height: '40px',
                                                                    display: '-webkit-box !important',
                                                                    '-webkit-line-clamp': 2,
                                                                    '-webkit-box-orient': 'vertical',
                                                                    'max-width': '50%',
                                                                }
                                                            "
                                                        >
                                                            {{ pageData.page4.template['LI'][item[0]].title }}
                                                        </p>
                                                        <p
                                                            v-else-if="value.function_type == '3rdParty'"
                                                            class="widget-title"
                                                            :style="
                                                                index !== 0 && {
                                                                    'white-space': 'normal',
                                                                    height: '40px',
                                                                    display: '-webkit-box !important',
                                                                    '-webkit-line-clamp': 2,
                                                                    '-webkit-box-orient': 'vertical',
                                                                    'max-width': '50%',
                                                                }
                                                            "
                                                        >
                                                            {{ value.function_name }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>

                                    <div class="w-20 p-2 side-container">
                                        <div
                                            class="side-container-item"
                                            v-for="(item, index) in Object.entries(pageData.page4.template.GSI)"
                                            :key="item[1].name + ''"
                                            :style="item[1].style"
                                        >
                                            <draggable
                                                class="draggable-list"
                                                :list="pageData.page4.template['GSI'][item[0]].elements"
                                                :group="
                                                    pageData.page4.template['GSI'][item[0]].group
                                                        ? pageData.page4.template['GSI'][item[0]].group
                                                        : { name: 'myGroup', pull: false, put: true }
                                                "
                                                @change="manipulateWidgets($event, 'GSI', item[1].name, 'add')"
                                            >
                                                <div
                                                    :class="value.class"
                                                    v-for="value in pageData.page4.template['GSI'][item[0]].elements"
                                                    :key="value.name"
                                                    @mouseover="hoveredElementId = 'GSI' + index"
                                                    @mouseleave="hoveredElementId = null"
                                                    @click="
                                                        value.function_type === 'generic'
                                                            ? addDetails(value.function_name, index)
                                                            : ''
                                                    "
                                                    :style="{
                                                        'background-color':
                                                            value.function_name == 'Web Links2' ? '#5155C3' : '',
                                                        'border-radius': '35px',
                                                        position: 'relative',
                                                        display: 'flex',
                                                        'justify-content': 'center',
                                                        'align-items': 'center',
                                                        height: '100%',
                                                    }"
                                                >
                                                    <p
                                                        v-if="
                                                            value.function_type == 'generic' &&
                                                            pageData.page4.template['GSI'][item[0]].title
                                                        "
                                                        class="widget-title d-flex justify-items-center align-items-center"
                                                        style="
                                                            white-space: wrap;
                                                            height: 40px;
                                                            margin-left: 15px;
                                                            display: -webkit-box !important;
                                                            -webkit-line-clamp: 2;
                                                            -webkit-box-orient: vertical;
                                                        "
                                                    >
                                                        {{ pageData.page4.template['GSI'][item[0]].title }}
                                                    </p>
                                                    <p
                                                        v-else-if="value.function_type == '3rdParty'"
                                                        class="widget-title d-flex justify-items-center align-items-center"
                                                        style="
                                                            white-space: wrap;
                                                            height: 40px;
                                                            margin-left: 15px;
                                                            display: -webkit-box !important;
                                                            -webkit-line-clamp: 2;
                                                            -webkit-box-orient: vertical;
                                                        "
                                                    >
                                                        {{ value.function_name }}
                                                    </p>
                                                    <img
                                                        v-if="
                                                            value.function_type === '3rdParty' ||
                                                            (value.function_type === 'generic' &&
                                                                pageData.page4.template['GSI'][item[0]].title)
                                                        "
                                                        class="mx-2"
                                                        :src="value.image"
                                                        alt=""
                                                        style="height: 50px; margin-right: 20px !important"
                                                    />
                                                    <img
                                                        v-else-if="value.function_type === '3rdParty'"
                                                        :src="value.image"
                                                        alt=""
                                                        style="max-width: 70%"
                                                    />
                                                    <img
                                                        v-else-if="value.function_name === 'Care Team2'"
                                                        :src="value.image"
                                                        alt=""
                                                        style="border-radius: 0.5rem; max-width: 40%"
                                                    />
                                                    <img
                                                        v-else-if="
                                                            value.function_name === 'Mood Tracker' ||
                                                            value.function_name === 'Education'
                                                        "
                                                        :src="value.image"
                                                        alt=""
                                                        style="max-width: 70%; max-height: 85%"
                                                    />
                                                    <img v-else :src="value.image" alt="" style="max-width: 70%" />
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex w-100 align-items-center"
                                style="position: absolute; bottom: 0; left: 0; border-radius: 10px 20px"
                            >
                                <img
                                    src="@/assets/px/drag-and-drop/footer.png"
                                    alt=""
                                    style="max-height: 4rem; border-radius: 10px 20px; width: 100%"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ### Custom Loader ### -->
        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import draggable from 'vuedraggable';

    export default {
        name: 'PreviewModalMHH_3CallButton',
        components: { NoButtonModal, draggable },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            selected: {
                type: String,
            },
            pageData: {
                type: Object,
                required: true,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                showAppListing: true,
                showLoader: false,
            };
        },
        computed: {
            moduleItems() {
                return Object.entries(this.pageData.page4.template.LI).slice(0, 4);
            },
            callBtnItems() {
                return Object.entries(this.pageData.page4.template.LI).slice(4);
            },
        },
        methods: {
            moduleItemsStyle(item) {
                if (!item[1]?.style) {
                    return '';
                }

                let styleObj = Object.fromEntries(
                    item[1]?.style?.split(';').map((style) => {
                        const [key, value] = style?.trim().split(':');
                        return [key?.toLowerCase(), value?.trim()];
                    })
                );

                delete styleObj[''];

                styleObj = {
                    ...styleObj,
                    'background-color':
                        this.pageData.page4.template['LI'][item[0]].elements.length &&
                        this.pageData.page4.template['LI'][item[0]].elements[0].function_name === 'Scheduling2'
                            ? 'transparent'
                            : styleObj['background-color'] ?? '',
                    'box-shadow':
                        this.pageData.page4.template['LI'][item[0]].elements.length &&
                        this.pageData.page4.template['LI'][item[0]].elements[0].function_name === 'Scheduling2'
                            ? ''
                            : '5px 10px 5px 5px rgba(0, 0, 0, 0.07), 0px 6px 4px 0px rgba(255, 255, 255, 0.07) inset',
                    'border-radius': '35px',
                    width: '12rem',
                    height: '75%',
                };

                return { ...styleObj };
            },
            callBtnItemsStyle(item, index) {
                if (!item[1]?.style) {
                    return '';
                }

                let styleObj = Object.fromEntries(
                    item[1]?.style?.split(';').map((style) => {
                        const [key, value] = style?.trim().split(':');
                        return [key?.toLowerCase(), value?.trim()];
                    })
                );

                delete styleObj[''];

                styleObj = {
                    ...styleObj,
                    'background-color':
                        this.pageData.page4.template['LI'][item[0]].elements.length &&
                        this.pageData.page4.template['LI'][item[0]].elements[0].function_name === 'Scheduling2'
                            ? 'transparent'
                            : styleObj['background-color'] ?? '',
                    'box-shadow':
                        this.pageData.page4.template['LI'][item[0]].elements.length &&
                        this.pageData.page4.template['LI'][item[0]].elements[0].function_name === 'Scheduling2'
                            ? ''
                            : '5px 10px 5px 5px rgba(0, 0, 0, 0.07), 0px 6px 4px 0px rgba(255, 255, 255, 0.07) inset',
                    'border-radius': '35px',
                    width: '15rem',
                    height: index === 0 ? '80%' : '50%',
                };

                return { ...styleObj };
            },
            cancel() {
                this.showAppListing = true;
                this.isEditScreen = false;

                this.$emit('close');
            },
        },
        created() {},
    };
</script>
<style scoped>
    .loader-main {
        min-height: 50vh;
    }

    .add-list-function-loader {
        height: 2em;
        width: 2em;
        font-weight: bolder;
    }

    .img-loader-main {
        min-height: 30px;
    }

    .img-loader {
        height: 0.9em;
        width: 0.9em;
        font-weight: 600;
    }

    .img-loader-text {
        font-size: 0.8em;
        font-weight: 600;
        color: var(--base-theme-color);
    }

    .icon-container {
        color: #271e4a;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-weight: 600;
    }

    .side-container {
        display: flex;
        gap: 30px;
        flex-direction: column;
        justify-content: center;
        margin-right: 2rem;
    }

    .side-container-item {
        height: 23%;
        background-color: white;
        border-radius: 35px;
        box-shadow: 5px 10px 5px 5px rgba(0, 0, 0, 0.07), 0px 6px 4px 0px rgba(255, 255, 255, 0.07) inset;
    }

    .li-items-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 35px;
        height: 100%;
    }

    .widget-title {
        height: 20px;
        width: 80%;
        margin-top: 0.5rem;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        text-transform: capitalize;
    }
</style>
