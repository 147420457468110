<template>
    <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal">
        <div class="modal-list-third-party-app" v-if="showAppListing">
            <div class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                <span class="label-app-list-header">Choose new template</span>
                <div class="d-flex justify-content-between align-items-center">
                    <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                        <span id="target" class="material-symbols-outlined btn-popup-close-dashboardbuilder ms-2 rounded-circle" role="button"
                            @click="cancel">close</span>
                    </ejs-tooltip>
                </div>
            </div>
          
            <CustomCarousel v-bind:templateData="templateData" @selection-changed="updateSelectedOption" :selectedOption="selected" :pageData="pageData" />
       

            <template v-if="!showLoader">

                <div class="d-flex justify-content-end" style="margin-right:10vh;">
                    <div class="d-flex">
                        <button type="button" class="primary btn-save-application-dashboardbuilder" @click="cancel"  >Save </button>

                    </div>
                </div>
            </template>
        </div>

        <!-- ### Custom Loader ### -->
        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import CustomCarousel from '@/components/dashboard/CustomCarousel.vue'
import { dashboardBuilder } from '@/util/apiRequests';


export default {
    name: 'DashboardBuilderModal',
    components: { NoButtonModal, CustomCarousel },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        pageData: {
            type: Object
        },
        selected:{
            type:Number,
            
        },
        applicationFrame:{
            type:String
        }

    },
    data() {
        return {
            templateData: [],
            showAppListing: true,
            showLoader: false,
            selectedOption: this.selected,
        };
    },
    methods: {
       
        cancel() {
            this.showAppListing = true;
            this.isEditScreen = false;
            this.$emit('selection-changed', this.selectedOption);
            this.$emit('close');
        },
        updateSelectedOption(option) {
      this.selectedOption = option;
    },
        async getAllTemplate() {
            let metaData = await this.$api.get(dashboardBuilder.getAllTemplate());
            let finallist=[];
            metaData.data.templateMetaList.map((val)=>{
                if(val.project_name==this.applicationFrame)
                {
                    finallist.push(val);
                }
                
            })
            
            this.templateData = finallist;
            
          

        },
       

    },
    created() {
        this.getAllTemplate();
    }
};
</script>
<style scoped>
.loader-main {
    min-height: 50vh;
}

.add-list-function-loader {
    height: 2em;
    width: 2em;
    font-weight: bolder;
}

.img-loader-main {
    min-height: 30px;
}

.img-loader {
    height: .9em;
    width: .9em;
    font-weight: 600;
}

.img-loader-text {
    font-size: .8em;
    font-weight: 600;
    color: var(--base-theme-color);
}

/* ### Custom scroll ### */

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--base-theme-color);
}
</style>
