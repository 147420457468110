<template>
    <div class="page-body education-feed-modal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app">
                <div
                    class="modal-content-header modal-list-third-party-app d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
                >
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <span class="label-app-list-header"> Education Feed </span
                        ><button @click="addNew()" type="button" class="no-bg btn-info px-4 mx-4" v-if="!isAddField">
                            Add Item
                        </button>
                    </div>
                </div>
                <template v-if="isAddField && !loading">
                    <div class="d-flex px-5 w-80 align-items-center pt-2">
                        <label style="font-size: 1rem" for="sorting"> Use External Link </label>
                        <Toggle
                            id="sorting"
                            class="toggle-switch"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            v-model="rssDetails.isExternalUrl"
                            @input="handleToggle($event)"
                        />
                    </div>

                    <div class="m-4 py-4" style="min-height: 10rem">
                        <div v-if="showExternalLink" class="d-flex flex-column" style="gap: 20px">
                            <div class="d-flex align-items-center">
                                <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="title">
                                    Feed Title*
                                </label>
                                <input
                                    class="w-70"
                                    type="text"
                                    name="title"
                                    id="education-title"
                                    placeholder="Enter Feed Title"
                                    v-model="rssDetails.title"
                                />
                            </div>
                            <div class="d-flex align-items-center">
                                <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="url">
                                    Feed URL*
                                </label>
                                <input
                                    class="w-70"
                                    type="text"
                                    name="url"
                                    id="url"
                                    placeholder="Enter Feed URL"
                                    v-model="rssDetails.url"
                                />
                            </div>
                        </div>
                        <div v-else class="d-flex align-items-center">
                            <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="rss">
                                Select Feed*
                            </label>
                            <Multiselect
                                id="rss"
                                track-by="id"
                                label="feedtitle"
                                class="w-70"
                                placeholder="Search item..."
                                v-model="selected_rss"
                                :options="rss_list"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                            />
                        </div>
                    </div>

                    <div class="section-footer add-new-app-form my-4">
                        <div class="d-flex justify-content-center" style="bottom: 10%">
                            <button type="button" class="primary btn-save" @click="saveRssFeed">Add</button>
                            <button
                                type="button"
                                class="no-bg btn-cancel px-4"
                                @click="
                                    () => {
                                        isAddField = false;
                                    }
                                "
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                </template>
                <template v-else-if="!loading">
                    <div v-if="feedList.length == 0" class="mx-5">Add Feed items to show</div>
                    <div v-else v-for="(feed, index) in feedList" :key="index">
                        <div class="profile-listing-body d-flex mx-5 justify-content-between align-items-center">
                            <!-- <ejs-tooltip
                                v-if="feedList.length > 1"
                                class="tooltipcontainer"
                                content="Reorder"
                                target="#target"
                                cssClass="customtooltip"
                                position="BottomRight"
                            >
                                <img id="target" src="@/assets/px/drag-and-drop/drag_and_drop_icon.svg" alt="" />
                            </ejs-tooltip> -->
                            <span
                                class="w-70 label-app-list-header"
                                style="
                                    text-overflow: ellipsis;
                                    text-wrap: nowrap;
                                    overflow: hidden;
                                    text-transform: capitalize;
                                "
                                >{{ feed.title }}</span
                            >
                            <div class="d-flex">
                                <ejs-tooltip
                                    class="tooltipcontainer"
                                    content="Edit"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <span
                                        id="target"
                                        class="material-symbols-outlined icon-edit me-2"
                                        role="button"
                                        @click="editFeed(feed.id)"
                                    >
                                        edit_square
                                    </span>
                                </ejs-tooltip>
                                <ejs-tooltip
                                    class="tooltipcontainer"
                                    content="Delete"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <span
                                        id="target"
                                        class="material-symbols-outlined icon-delete"
                                        role="button"
                                        @click="deleteFeed(feed.id)"
                                    >
                                        delete
                                    </span>
                                </ejs-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="section-footer add-new-app-form my-4">
                        <div class="d-flex justify-content-center" style="bottom: 10%">
                            <button type="button" class="primary btn-save" @click="saveGenericModule()">Save</button>
                            <button
                                type="button"
                                class="no-bg btn-cancel px-4"
                                @click="
                                    () => {
                                        isAddField = false;
                                        $emit('cancel');
                                    }
                                "
                            >
                                Cancel
                            </button>
                        </div>
                    </div></template
                >
            </div>

            <div
                v-if="loading"
                class="d-flex flex-column justify-content-center align-items-center loader-main"
                style="min-height: 25rem"
            >
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { dashboardBuilder, memberProfile, rssFeeds, starkidEducation, education } from '@/util/apiRequests';

    const initialFunctionInfoState = {
        url: '',
        color: '',
        textColor: '',
        primaryColor: '',
        secondaryColor: '',
        isSensitive: false,
        enableQR: false,
        embeddedDashboardId: '',
        embeddedDashboardName: '',
        embeddedCompanyId: null,
        embeddedCompanyName: '',
        lumecatitle: '',
        location: '',
        selectedSchemeOption: 'primary_colors',
        phoneNumbers: [],
        file_id: null,
        document_id: null,
        sensitiveScreenTimeout: 0,
        sensitiveAlertTimeout: 0,
        enabledtms: false,
        dtmsSettings: [],
        title: 'Education',
        assigned_to: 'Education',
        isEmbedded: false,
        isMaster: false,
    };

    export default {
        name: 'EducationFeedEditmodal',
        components: { NoButtonModal },
        data() {
            return {
                loading: true,
                showExternalLink: false,
                isAddField: false,
                dashboard_id: null,
                selected_rss: '',
                feedList: [],
                rssDetails: {
                    title: '',
                    url: '',
                    isExternalUrl: false,
                },
                rss_list: [],
                functionInfo: { ...initialFunctionInfoState },
            };
        },
        props: {
            rssFeedsDetails: {
                type: Object,
            },
            clickedProfileFunction: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                required: true,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
            pageData: {
                type: Object,
            },
        },
        methods: {
            async saveGenericModule() {
                if (!this.functionInfo?.id && this.feedList?.length) {
                    const endpoint = memberProfile.createGenericModule();
                    await this.$api.post(endpoint, {
                        ...this.functionInfo,
                        assigned_to: this.clickedProfileFunction,
                        isMaster: this.pageData?.page2?.isMaster,
                        position: this.position,
                        dashboard_assigned: this.dashboard_id,
                        application: this.applicationFrame,
                        issaved: 0,
                    });
                    this.$toasted.success('Education added successfully !');
                }
                this.$emit('cancel');
            },
            addNew() {
                this.isAddField = true;
                this.rssDetails = {
                    title: '',
                    url: '',
                    isExternalUrl: false,
                };
                this.showExternalLink = false;
            },
            editFeed(id) {
                this.rssDetails = this.feedList.find((item) => item.id == id);
                this.rssDetails = {
                    ...this.rssDetails,
                    url: this.rssDetails.link,
                    isExternalUrl: this.rssDetails.type == 'hyperlink' ? true : false,
                };
                if (this.rssDetails?.rssfeedid) {
                    this.selected_rss = this.rss_list.find((item) => item.id === this.rssDetails?.rssfeedid) ?? '';
                }
                this.isAddField = true;
                this.showExternalLink = this.rssDetails.type == 'hyperlink' ? true : false;
            },
            async fetchApplications() {
                const endpoint = memberProfile.getSpecificGenericFunction(
                    this.clickedProfileFunction,
                    this.position,
                    this.dashboard_id
                );
                const functionResponse = await this.$api.get(endpoint);
                if (!functionResponse) {
                    throw 'e';
                }

                if (functionResponse?.data?.info?.length) {
                    this.functionInfo = functionResponse?.data?.info[0];
                }
            },
            async getRssList() {
                try {
                    let list = await this.$api.get(rssFeeds.getRssList());
                    let data = list.data.info;
                    this.rss_list = data;
                    if (!this.rssDetails.isExternalUrl && this.rssDetails?.id) {
                        this.selected_rss = this.rss_list.filter((item) => item.id == this.rssDetails?.id);
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetched RSS Items');
                }
            },
            async saveRssFeed() {
                if (this.rssDetails.isExternalUrl && (this.rssDetails.url == '' || this.rssDetails.title == '')) {
                    return this.$toasted.error('Please fill all required fields');
                }
                if (!this.rssDetails.isExternalUrl && this.selected_rss == '') {
                    return this.$toasted.error('Please select any feed');
                }
                if (!this.rssDetails.isExternalUrl) {
                    this.rssDetails = {
                        id: this.rssDetails.id ? this.rssDetails.id : null,
                        type: 'rssfeed',
                        title: this.selected_rss.feedtitle,
                        rssfeedid: this.selected_rss.id,
                        dashboard_id: this.dashboard_id,
                        defaultpinstatus: false,
                    };
                } else {
                    this.rssDetails = {
                        id: this.rssDetails.id ? this.rssDetails.id : null,
                        title: this.rssDetails.title,
                        type: 'hyperlink',
                        link: this.rssDetails.url,
                        dashboard_id: this.dashboard_id,
                        defaultpinstatus: false,
                    };
                }

                if (this.rssDetails.id != null) {
                    if (this.rssDetails.rssfeedid == null) {
                        delete this.rssDetails.rssfeedid;
                    }
                    let endpoint;
                    if (['star-kid', 'mobex-health-plus'].includes(this.applicationFrame)) {
                        endpoint = starkidEducation.updateEducation(
                            this.rssDetails.id,
                            this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus'
                        );
                    } else {
                        endpoint = education.updateEducation(this.rssDetails.id);
                    }
                    await this.$api.put(endpoint, this.rssDetails);
                } else {
                    let endpoint;
                    if (['star-kid', 'mobex-health-plus'].includes(this.applicationFrame)) {
                        endpoint = starkidEducation.addEducation(
                            this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus'
                        );
                    } else {
                        endpoint = education.addEducation(this.applicationFrame);
                    }
                    await this.$api.post(endpoint, this.rssDetails);
                }
                this.getEducationById();
                this.isAddField = false;
            },
            async deleteFeed(id) {
                let endpoint;
                if (['star-kid', 'mobex-health-plus'].includes(this.applicationFrame)) {
                    endpoint = starkidEducation.deleteEducationResource(
                        id,
                        this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus'
                    );
                } else {
                    endpoint = education.deleteEducationResource(id);
                }

                await this.$api.delete(endpoint);
                this.getEducationById();
            },
            handleToggle(e) {
                this.showExternalLink = e;
            },
            async getEducationById() {
                let endpoint;
                if (['star-kid', 'mobex-health-plus'].includes(this.applicationFrame)) {
                    endpoint = starkidEducation.getEducationById(
                        this.dashboard_id,
                        this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus'
                    );
                } else {
                    endpoint = education.getEducationById(this.dashboard_id);
                }

                const response = await this.$api.get(endpoint);
                if (response?.data?.success) {
                    this.feedList = response?.data?.message;
                }
            },
        },
        async created() {
            if (this.$route?.query?.id) {
                this.dashboard_id = this.$route?.query?.id;
            } else {
                const lastidendpoint = dashboardBuilder.getLastTemplateId();
                const res = await this.$api.get(lastidendpoint);
                if (res.data.success) {
                    this.dashboard_id = res.data.response.AUTO_INCREMENT;
                }
            }
            await this.fetchApplications();
            await this.getRssList();
            if (this.rssFeedsDetails && Object.entries(this.rssFeedsDetails).length) {
                this.rssDetails = {
                    ...this.rssFeedsDetails,
                    isExternalUrl: this.rssFeedsDetails.isExternalUrl ? true : false,
                };
                this.showExternalLink = this.rssDetails.isExternalUrl;
            }
            await this.getEducationById();
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        font-size: 18px;
        color: #2e3343;
        margin: 0 0 20px;
        background-color: #f1f7ff;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
    .btn-info,
    .btn-info:hover {
        border-radius: 10px;
        border: 2px solid #5155c2;
        color: #5155c2;
    }
</style>

<style>
    .education-feed-modal .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
    }

    .education-feed-modal .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }
</style>
